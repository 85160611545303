import React from "react";
import { Form } from "react-bootstrap";
import axios from "axios";

type Props = {
  des: string;
  placeholder?: string | undefined;
  posturl: string;
  setParts: Function;
};

const eventHandler = (posturl: string, des: string, setParts: Function) => {
  axios
    .post(`/portfolio/desc/${posturl}`, {
      filedesc: des,
    })
    .then(function (response) {
      setParts(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const Description = ({ posturl, des, setParts, placeholder }: Props) => {
  return (
    <div>
      <Form.Control
        type="text"
        name="description"
        onBlur={(e: any) => eventHandler(posturl, e.target.value, setParts)}
        defaultValue={des}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Description;
