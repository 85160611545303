import React, { useContext } from "react";
import { AuthStoreContext } from "../../stores";
import { Button, Card, Form, Col, Row } from "react-bootstrap";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { observer } from "mobx-react-lite";
import UploadImage from "./UploadImage";

interface IFormInputs {
  firstnameThai: string;
  lastnameThai: string;
  firstnameEnglish: string;
  lastnameEnglish: string;
  nationalId: string;
  applicationId: string;
}

const schema = yup.object().shape({
  firstnameThai: yup
    .string()
    .required()
    .matches(/^[ก-๏\s]+$/),
  lastnameThai: yup
    .string()
    .required()
    .matches(/^[ก-๏\s]+$/),
  firstnameEnglish: yup
    .string()
    .required()
    .matches(/^[a-zA-Z\s]+$/),
  lastnameEnglish: yup
    .string()
    .required()
    .matches(/^[a-zA-Z\s]+$/),
  nationalId: yup
    .string()
    .required()
    .length(13)
    .matches(/^[0-9]{13}$/),
  applicationId: yup.string().matches(/^$|^[0-9]{8}$/),
});

const redirect_uri: string = "/portfolio/";

const PortfolioRegister = observer(() => {
  const authStore = useContext(AuthStoreContext);
  const userInfo = authStore.userInfo;
  const [history] = useState(useHistory());

  const { register, handleSubmit, errors } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: IFormInputs) => {
    axios
      .patch(`/user/update/`, data)
      .then(function (response) {
        authStore.setUserInformation(response.data);
        history.push(redirect_uri);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const agreeTnC = () => {
    axios.patch(`/user/update/`, {
      agreedToTnC: true,
    });
  };

  return (
    <>
      <div className="container">
        <div className="justify-content-md-center py-3">
          <Card bg="lite" text="dark" className="mb-2">
            <Card.Header className="text-center">ข้อมูลผู้สมัคร</Card.Header>
            <Card.Body>
              <div className="form py-3">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <UploadImage />
                  <Form.Row className="pt-4">
                    <Form.Group as={Col}>
                      <Form.Label>
                        ชื่อ (ภาษาไทย) <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstnameThai"
                        placeholder="ชื่อ (ภาษาไทย)"
                        defaultValue={userInfo.firstnameThai as string}
                        ref={register}
                      />
                      {errors.firstnameThai?.type === "required" && (
                        <p className="text-danger">จำเป็นต้องกรอก</p>
                      )}
                      {errors.firstnameThai?.type === "matches" && (
                        <p className="text-danger">ต้องเป็นภาษาไทย</p>
                      )}
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>
                        นามสกุล (ภาษาไทย) <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lastnameThai"
                        placeholder="นามสกุล (ภาษาไทย)"
                        defaultValue={userInfo.lastnameThai as string}
                        ref={register}
                      />
                      {errors.lastnameThai?.type === "required" && (
                        <p className="text-danger">จำเป็นต้องกรอก</p>
                      )}
                      {errors.lastnameThai?.type === "matches" && (
                        <p className="text-danger">ต้องเป็นภาษาไทย</p>
                      )}
                    </Form.Group>
                  </Form.Row>

                  <Form.Row className="py-1">
                    <Form.Group as={Col}>
                      <Form.Label>
                        ชื่อ (ภาษาอังกฤษ) <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="firstnameEnglish"
                        placeholder="ชื่อ (ภาษาอังกฤษ)"
                        defaultValue={userInfo.firstnameEnglish as string}
                        ref={register}
                      />
                      {errors.firstnameEnglish?.type === "required" && (
                        <p className="text-danger">จำเป็นต้องกรอก</p>
                      )}
                      {errors.firstnameEnglish?.type === "matches" && (
                        <p className="text-danger">
                          ต้องเป็นภาษาอังกฤษ
                        </p>
                      )}
                    </Form.Group>

                    <Form.Group as={Col}>
                      <Form.Label>
                        นามสกุล (ภาษาอังกฤษ) <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="lastnameEnglish"
                        placeholder="นามสกุล (ภาษาอังกฤษ)"
                        defaultValue={userInfo.lastnameEnglish as string}
                        ref={register}
                      />
                      {errors.lastnameEnglish?.type === "required" && (
                        <p className="text-danger">จำเป็นต้องกรอก</p>
                      )}
                      {errors.lastnameEnglish?.type === "matches" && (
                        <p className="text-danger">
                          ต้องเป็นภาษาอังกฤษ
                        </p>
                      )}
                    </Form.Group>
                  </Form.Row>

                  <Form.Group className="pt-4" as={Row}>
                    <Form.Label column sm={3}>
                      หมายเลขบัตรประชาชน 13 หลัก{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type="text"
                        name="nationalId"
                        defaultValue={userInfo.nationalId as string}
                        ref={register}
                      />
                      {errors.nationalId?.type === "required" && (
                        <p className="text-danger">จำเป็นต้องกรอก</p>
                      )}
                      {errors.nationalId?.type === "matches" && (
                        <p className="text-danger">ต้องเป็นตัวเลข</p>
                      )}
                      {errors.nationalId?.type === "length" && (
                        <p className="text-danger">
                          ต้องเป็นตัวเลข 13 หลัก
                        </p>
                      )}
                    </Col>
                  </Form.Group>

                  <Form.Group className="pt-3" as={Row}>
                    <Form.Label column sm={3}>
                      เลขที่ใบสมัคร <br />
                      <span className="text-danger">
                        (สามารถกรอกหลังจากได้รับเลขที่ใบสมัครของศูนย์ทดสอบ)
                      </span>
                    </Form.Label>
                    <Col sm={9}>
                      <Form.Control
                        type="text"
                        name="applicationId"
                        defaultValue={userInfo.applicationId as string}
                        ref={register}
                      />
                      {errors.applicationId?.type === "matches" && (
                        <p className="text-danger">
                          ต้องเป็นตัวเลข 8 หลัก
                        </p>
                      )}
                    </Col>
                  </Form.Group>

                  {!userInfo.agreedToTnC && (
                    <div className="pt-3">
                      {/* <p>
                        <u>ข้อกำหนดและเงื่อนไข</u>
                      </p>
                      <p>
                        &emsp;&emsp;&emsp;การกรอกข้อมูลในระบบ E-Portfolio
                        ทางคณะวิศวกรรมศาสตร์จะรับพิจารณาแฟ้มสะสมผลงานรูปแบบอิเล็กทรอนิกส์
                        E-Portfolio เท่านั้น
                        หากผู้สมัครไม่ส่งแฟ้มสะสมผลงานที่สั่งพิมพ์จากระบบอิเล็กทรอนิกส์
                        (E-Portfolio) มาพร้อมหลักฐานประกอบการสมัคร คณะกรรมการฯ
                        จะถือว่าผู้สมัครเป็นผู้ขาดคุณสมบัติในการสมัครสอบครั้งนี้
                      </p> */}
                      <p>
                        <u>นโยบายคุ้มครองข้อมูลส่วนบุคคล</u>
                      </p>
                      <p>
                        &emsp;&emsp;&emsp;คณะวิศวกรรมศาสตร์ให้ความสำคัญต่อความปลอดภัยของข้อมูลส่วนบุคคลของผู้สมัคร สามารถศึกษาเพิ่มเติมได้โดย <a href="#" className="link-dark">คลิกที่นี่</a>
                      </p>

                      <div className="text-center pt-2">
                        <Form.Group>
                          <Form.Check
                            required
                            label="ฉันยอมรับข้อกำหนดและเงื่อนไข"
                            feedback="You must agree before submitting."
                            onClick={agreeTnC}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  )}

                  <div className="submit-button col-sm-6 mx-auto py-3">
                    {authStore.profilePic === undefined && (
                      <div className="text-center pb-2">
                        <p className="text-danger">
                          *** กรุณาอัปโหลดรูปถ่ายเพื่อดำเนินการต่อ ***
                        </p>
                      </div>
                    )}
                    {!authStore.isfinalized &&
                    authStore.profilePic !== undefined ? (
                      <Button variant="primary" type="submit" size="lg" block>
                        เริ่มสร้าง Portfolio
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        type="submit"
                        size="lg"
                        block
                        disabled
                      >
                        เริ่มสร้าง Portfolio
                      </Button>
                    )}
                  </div>
                </Form>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
});

export default PortfolioRegister;
