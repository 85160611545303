import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import Image from "./Image";
import "./style.scss";

const UploadImage = () => {
  return (
    <Row>
      <Col sm={5}>
        <Image />
      </Col>
      <Col sm={6}>
        <Card border="danger">
          <Card.Header className="warning-header-bg">
            ข้อกำหนดไฟล์ภาพถ่าย
          </Card.Header>
          <Card.Body className="warning-body-bg">
            <Card.Text>
              – เป็นไฟล์นามสกุล .jpg ซึ่งมีขนาด 475x600 พิกเซล
            </Card.Text>
            <Card.Text>
              - ต้องเป็นภาพที่เหมือนกับที่ใช้สมัครผ่านเว็บไซต์ของศูนย์ทดสอบฯ
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default UploadImage;
