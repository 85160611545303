import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  ListGroup,
  Col,
  Row,
  Modal,
  Form,
} from "react-bootstrap";
import PdfGen from "./PdfGen";
import axios from "axios";
import { observer } from "mobx-react-lite";
import { AuthStoreContext } from "../../stores";
import { Part } from "../../types";
import "./style.scss";

const MyVerticallyCenteredModal = (props: any) => {
  const [check, setcheck] = useState(false);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      animation={false}
    >
      <Form onSubmit={(e) => e.preventDefault()}>
        <Modal.Header closeButton className="text-center">
          <Modal.Title id="contained-modal-title-vcenter">
            ยืนยันการสร้างแฟ้มสะสมผลงานอิเล็กทรอนิกส์ (E-Portfolio) ฉบับสมบูรณ์
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          ผู้สมัครได้ตรวจสอบแฟ้มสะสมผลงาน อิเล็กทรอนิกส์ (E-Portfolio)
          เรียบร้อยแล้ว <br />
          <br />
          <h4 className="text-danger">
            *** เมื่อท่านยืนยันการสร้างแฟ้มสะสมผลงานอิเล็กทรอนิกส์ (E-Portfolio) ฉบับสมบูรณ์ 
            และ กดปุ่ม "ตกลง" แล้ว จะไม่สามารถแก้ไขข้อมูลใด ๆ อีก ***
          </h4>
          <Form.Group className="pt-3">
            <Form.Check
              required
              label="ยืนยันการสร้างแฟ้มสะสมผลงานอิเล็กทรอนิกส์ (E-Portfolio)"
              feedback="กรุณากดยืนยันก่อนดำเนินการต่อ"
              checked={check}
              onChange={() => setcheck(!check)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button
            className="mr-auto"
            variant="secondary"
            onClick={props.onHide}
            type="button"
          >
            ยกเลิก
          </Button>

          <Button
            variant="primary"
            type="submit"
            className={!check ? `not-allowed disabled` : ``}
          >
            ตกลง
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const PortfolioExample = observer(() => {
  const authStore = useContext(AuthStoreContext);
  const [modalShow, setModalShow] = useState(false);
  const [history] = useState(useHistory());
  const [parts, setParts] = useState<Part[]>([]);
  const userInfo = authStore.userInfo;

  useEffect(() => {
    axios.get(`/portfolio/me`).then((response) => {
      setParts(response.data);
    });
  }, []);

  useEffect(() => {
    if (authStore.isfinalized) {
      history.push("/portfolio/finalize");
    }
  }, [authStore.isfinalized])

  const onSubmit = () => {
    axios
      .post(`/portfolio/finalize/`, {})
      .then(function (response) {
        authStore.setFinalized();
        history.push("/portfolio/finalize");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className="container py-3">
      <Row>
        <Col sm={4}>
          <Card bg="lite" text="dark" className="mb-2">
            <Card.Header className="text-center">
              แสดงตัวอย่างแฟ้มสะสมผลงานอิเล็กทรอนิกส์ (E-Portfolio)
            </Card.Header>
            <ListGroup.Item>
              <Link to="/portfolio">
                <Button variant="secondary" size="lg" block>
                  ย้อนกลับ
                </Button>
              </Link>
            </ListGroup.Item>
            <ListGroup.Item>
              {!authStore.isfinalized ? (
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => setModalShow(true)}
                  block
                >
                  สร้างแฟ้มสะสมผลงานอิเล็กทรอนิกส์ (E-Portfolio) ฉบับสมบูรณ์
                </Button>
              ) : (
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => setModalShow(true)}
                  block
                  disabled
                >
                  สร้างแฟ้มสะสมผลงานอิเล็กทรอนิกส์ (E-Portfolio) ฉบับสมบูรณ์
                </Button>
              )}
            </ListGroup.Item>
          </Card>
        </Col>
        <Col sm={8} className="text-center">
          <PdfGen parts={parts} userInfo={userInfo} />
        </Col>
      </Row>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        onSubmit={onSubmit}
      />
    </div>
  );
});

export default PortfolioExample;
