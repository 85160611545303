import React from "react";

const Footer = () => {
  return (
    <div className="text-center py-3 bg-dark text-white">
      <br />
      <p>
        หากมีข้อสงสัย สามารถสอบถามข้อมูลเพิ่มเติมได้ที่ฝ่ายวิชาการ
        คณะวิศวกรรมศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย
      </p>
      <p>
        โทร: 0-2218-6333-6 (ในวันและเวลาราชการ)
      </p>
      <p>
        หรือนอกเวลาราชการได้ที่ อีเมล:{" "}
        <a href="mailto:admission.eng@chula.ac.th" className="text-white">
          admission.eng@chula.ac.th
        </a>
      </p>
    </div>
  );
};

export default Footer;
