import React from "react";
import Routes from "./routes";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import Spinner from "./components/Spinner";
import Banner from "./components/Banner";
import { BrowserRouter as Router } from "react-router-dom";
import { authStore, AuthStoreContext } from "./stores";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <AuthStoreContext.Provider value={authStore}>
      <Router>
        <Banner />
        <Spinner />
        <Navigation />
        <Routes />
        <Footer />
      </Router>
    </AuthStoreContext.Provider>
  );
}

export default App;
