import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import SingleFileAutoSubmit from "../../../../components/SingleFileAutoSubmit";
import { Part } from "../../../../types";

type Props = {
  docs: Part;
  setParts: Function;
};

const Document2 = ({ docs, setParts }: Props) => {
  return (
    <div>
      <Card bg="lite" text="dark" className="mb-2">
        <Card.Header className="text-center">
          2) แรงผลักดันหรือแรงจูงใจในการเรียนคณะวิศวฯ และ 3) ความสามารถในการสื่อสาร
        </Card.Header>

        <ListGroup className="list-group-flush">
          <ListGroup.Item>
            <Card.Body className="text-danger">
              <Card.Title> คำอธิบายวิธีการอัปโหลด </Card.Title>
              <Card.Text>
                - นามสกุลของไฟล์หลักฐานที่สามารถแนบได้ต้องเป็นไฟล์วีดิโอนามสกุล
                .mp4 และ .mov เท่านั้น
              </Card.Text>
              <Card.Text>
                - หากมีการอัปโหลดไฟล์ใหม่
                ไฟล์เก่าที่อัปโหลดไว้จะถูกเขียนทับด้วยไฟล์ใหม่
              </Card.Text>
            </Card.Body>
          </ListGroup.Item>
        </ListGroup>

        <ListGroup.Item>
          <Card.Body>
            <Card.Title>
              คลิปวิดีโอแสดงถึงเจตนาและเป้าหมายของตนเองในการเข้าศึกษาที่คณะวิศวกรรมศาสตร์จุฬาฯ{" "}
            </Card.Title>
            <p className="text-danger">(จำเป็นต้องอัปโหลด)</p>
            - ความยาวไม่เกิน 3 นาที <br />-
            แสดงถึงเจตนาและเป้าหมายของตนเองในการเข้าศึกษาที่คณะวิศวกรรมศาสตร์จุฬาฯ
            <div className="pt-4">
              <SingleFileAutoSubmit
                posturl={`2/1/1`}
                acceptType=".mp4,.mov"
                qrurl={docs.secs[0].files[0].url}
                setParts={setParts}
              />
            </div>
          </Card.Body>
        </ListGroup.Item>
      </Card>
    </div>
  );
};

export default Document2;
