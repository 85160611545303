import React from "react";
import { Route, Switch } from "react-router-dom";
import PortfolioLogin from "./../pages/PortfolioLogin";
import PortfolioRegister from "../pages/PortfolioRegister";
import PortfolioManager from "./../pages/PortfolioManager";
import Callback from "./../pages/Callback";
import RequireAuth from "../components/RequireAuth";
import PortfolioExample from "../pages/PortfolioExample";
import PortfolioFinal from "../pages/PortfolioFinal";
import NotFound from "../pages/NotFound";

const Routes = () => {
  return (
    <div id="mcvev-mainview" className="container">
      <Switch>
        <Route exact path="/" component={PortfolioLogin} />
        <Route exact path="/oauth/callback" component={Callback} />
        <Route
          exact
          path="/register"
          component={(props: any) => (
            <RequireAuth {...props} Component={PortfolioRegister} />
          )}
        />
        {/* <Route
          exact
          path="/portfolio"
          component={PortfolioManager}
        /> */}
        <Route
          exact
          path="/portfolio"
          component={(props: any) => (
            <RequireAuth {...props} Component={PortfolioManager} />
          )}
        />
        <Route
          exact
          path="/portfolio/example"
          component={(props: any) => (
            <RequireAuth {...props} Component={PortfolioExample} />
          )}
        />
        <Route
          exact
          path="/portfolio/finalize"
          component={(props: any) => (
            <RequireAuth {...props} Component={PortfolioFinal} />
          )}
        />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default Routes;
