import React from "react";
import { Card, ListGroup, Row, Col } from "react-bootstrap";
import SingleFileAutoSubmit from "../../../../components/SingleFileAutoSubmit";
import { Part } from "../../../../types";

import GPAFormRowGroup from "./GPAFormRowGroup";
import Description from "../Document3/Description";

type Props = {
  docs: Part;
  setParts: Function;
};

const Document1 = ({ docs, setParts }: Props) => {
  return (
    <div>
      <Card bg="lite" text="dark" className="mb-2">
        <Card.Header className="text-center">1) ความสามารถทางวิชาการ</Card.Header>

        <ListGroup className="list-group-flush">
          <ListGroup.Item>
            <Card.Body className="text-danger">
              <Card.Title> คำอธิบายวิธีการอัปโหลด </Card.Title>
              <Card.Text>
                - นามสกุลของไฟล์หลักฐานที่สามารถแนบได้ คือ .jpg หรือ .pdf
                เท่านั้น
              </Card.Text>
              <Card.Text>
                - หากหลักฐานแต่ละประเภทมีหลายไฟล์
                ให้นำไฟล์มาเรียงต่อกันเป็นไฟล์เดียว
              </Card.Text>
              <Card.Text>
                - หากมีการอัปโหลดไฟล์ใหม่
                ไฟล์เก่าที่อัปโหลดไว้จะถูกเขียนทับด้วยไฟล์ใหม่
              </Card.Text>
            </Card.Body>
          </ListGroup.Item>
        </ListGroup>

        <ListGroup.Item>
          <Card.Body>
            <Card.Title>
              (คุณสมบัติของผู้สมัครข้อ 1.3) หลักฐานระเบียนแสดงผลการเรียนเฉลี่ยสะสม 5 ภาคการศึกษา{" "}
            </Card.Title>
            <p className="text-danger">(จำเป็นต้องอัปโหลด)</p>
            - ผลการเรียนเฉลี่ยสะสม 5 ภาคการศึกษาต้องไม่ต่ำกว่า 3.00 <br />-
            โดยกลุ่มวิชาวิทยาศาสตร์และกลุ่มวิชาคณิตศาสตร์
            แต่ละกลุ่มต้องเฉลี่ยไม่ต่ำกว่า 3.00
            <GPAFormRowGroup
              posturl={`1/1/1`}
              des={docs.secs[0].files[0].des}
              setParts={setParts}
            />
            <div className="pt-3">
              <SingleFileAutoSubmit
                posturl={`1/1/1`}
                acceptType=".jpg,.pdf"
                qrurl={docs.secs[0].files[0].url}
                setParts={setParts}
              />
            </div>
          </Card.Body>
        </ListGroup.Item>
        <ListGroup.Item>
          <Card.Body>
            <Card.Title>
              หลักฐานประกาศนียบัตร/รางวัล/เอกสารหลักฐาน ที่ได้รับรางวัลจากการสอบแข่งขันหรือตอบปัญหาทางวิชาการ 
              ด้านคณิตศาสตร์ วิทยาศาสตร์ วิศวกรรมศาสตร์ หรือเทคโนโลยีในระดับภูมิภาค หรือระดับชาติ หรือระดับนานาชาติ 
              รวมจำนวนไม่เกิน 3 ผลงาน
            </Card.Title>
            <Row className="pt-3">
              <Col sm={3}>
                <Card.Title>
                  ชื่อเวทีการสอบแข่งขันหรือตอบปัญหาทางวิชาการ ที่ 1
                </Card.Title>
              </Col>
              <Col sm={9}>
                <Description
                  placeholder={``}
                  posturl={`1/2/1`}
                  des={docs.secs[1].files[0].des}
                  setParts={setParts}
                />
              </Col>
            </Row>
            <div className="pt-4">
              <SingleFileAutoSubmit
                posturl={`1/2/1`}
                acceptType=".jpg,.pdf"
                qrurl={docs.secs[1].files[0].url}
                setParts={setParts}
                check={docs.secs[1].files[0].check}
              />
            </div>
          </Card.Body>
        </ListGroup.Item>
        <ListGroup.Item>
          <Card.Body>
            <Row className="pt-3">
              <Col sm={3}>
                <Card.Title>
                  ชื่อเวทีการสอบแข่งขันหรือตอบปัญหาทางวิชาการ ที่ 2
                </Card.Title>
              </Col>
              <Col sm={9}>
                <Description
                  placeholder={``}
                  posturl={`1/3/1`}
                  des={docs.secs[2].files[0].des}
                  setParts={setParts}
                />
              </Col>
            </Row>
            <div className="pt-4">
              <SingleFileAutoSubmit
                posturl={`1/3/1`}
                acceptType=".jpg,.pdf"
                qrurl={docs.secs[2].files[0].url}
                setParts={setParts}
                check={docs.secs[2].files[0].check}
              />
            </div>
          </Card.Body>
        </ListGroup.Item>
        <ListGroup.Item>
          <Card.Body>
            <Row className="pt-3">
              <Col sm={3}>
                <Card.Title>
                  ชื่อเวทีการสอบแข่งขันหรือตอบปัญหาทางวิชาการ ที่ 3
                </Card.Title>
              </Col>
              <Col sm={9}>
                <Description
                  placeholder={``}
                  posturl={`1/4/1`}
                  des={docs.secs[3].files[0].des}
                  setParts={setParts}
                />
              </Col>
            </Row>
            <div className="pt-4">
              <SingleFileAutoSubmit
                posturl={`1/4/1`}
                acceptType=".jpg,.pdf"
                qrurl={docs.secs[3].files[0].url}
                setParts={setParts}
                check={docs.secs[3].files[0].check}
              />
            </div>
          </Card.Body>
        </ListGroup.Item>
      </Card>
    </div>
  );
};

export default Document1;
