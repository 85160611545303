import React, { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import Dropzone, { IDropzoneProps } from "react-dropzone-uploader";
import { AuthStoreContext } from "../../../../stores";
import { observer } from "mobx-react-lite";
import "react-toastify/dist/ReactToastify.css";
import "react-dropzone-uploader/dist/styles.css";

const Image = observer(() => {
  const authStore = useContext(AuthStoreContext);

  const getUploadParams: IDropzoneProps["getUploadParams"] = () => {
    return {
      url: `${process.env.REACT_APP_ENDPOINT}/user/upload/profilepic/`,
      headers: { Authorization: `Bearer ${authStore.token}` },
    };
  };

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
    { meta, remove, xhr },
    status
  ) => {
    if (status === "headers_received") {
      toast.success(`${meta.name} uploaded!`);
      remove();
    } else if (status === "aborted") {
      toast.error(`${meta.name}, upload failed...`);
    } else if (status === "done") {
      let res = JSON.parse(xhr!.response);
      authStore.setProfilePic(res.S3Info.Location);
    }
  };

  return (
    <React.Fragment>
      <div className="text-center ">
        <p className="font-weight-bold">
          รูปถ่าย <span className="text-danger">*</span>
        </p>
        {authStore.profilePic === undefined ? (
          <img
            id="ProfilePic"
            src={`${process.env.PUBLIC_URL}/noimg.png`}
            width="160"
            height="200"
            alt="profile pic"
          ></img>
        ) : (
          <img
            id="ProfilePic"
            src={authStore.profilePic}
            width="160"
            height="200"
            alt="profile pic"
          ></img>
        )}
      </div>
      <br />
      <ToastContainer position="bottom-right" />

      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        accept=".jpg"
        maxFiles={1}
        multiple={false}
        canCancel={false}
        inputContent="คลิกหรือวางไฟล์เพื่ออัปโหลด"
        styles={{
          dropzoneActive: { borderColor: "green" },
          dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
        }}
      />
    </React.Fragment>
  );
});

export default Image;
