import React, { useEffect } from "react";
import { Part, UserInfo } from "../../../types";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf",
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
};

type Props = {
  parts: Part[];
  userInfo: UserInfo;
};

const PdfGen = ({ parts, userInfo }: Props) => {
  useEffect(() => {
    if (parts.length !== 0 && userInfo.agreedToTnC !== undefined) {
      let docDefinition = {
        header: function () {
          return {
            table: {
              widths: ["6%", "*", "25%"],
              body: [
                [
                  {
                    text: ``,
                  },
                  {
                    text: `\nแฟ้มสะสมผลงานอิเล็กทรอนิกส์ (E-Portfolio) เพื่อสมัคร TCAS68 รอบที่1 ปีการศึกษา 2568`,
                    alignment: "left",
                    bold: true,
                  },
                  {
                    text: `\nApplication ID: ${userInfo.applicationId}`,
                    alignment: "left",
                    bold: true,
                  },
                ],
              ],
            },
            layout: "noBorders",
          };
        },

        footer: function (currentPage: any, pageCount: any) {
          return {
            table: {
              widths: ["*"],
              body: [
                [
                  {
                    text: "Page " + currentPage + "/" + pageCount,
                    alignment: "center",
                  },
                ],
              ],
            },
            layout: "noBorders",
          };
        },

        watermark: {
          text: "ใช้เพื่อตรวจสอบความถูกต้องของข้อมูลเท่านั้น ยังไม่สามารถนำไปใช้งานจริงได้",
          color: "blue",
          opacity: 0.3,
          bold: true,
          italics: false,
        },
        info: {
          title: "ตัวอย่างเอกสารสำหรับพิมพ์ส่ง",
        },

        content: [
          {
            layout: "lightHorizontalLines",
            table: {
              headerRows: 1,
              widths: ["20%", "20%", "20%", "20%", "20%"],

              body: [
                [
                  {
                    text: `เลขที่ใบสมัครสอบ:\nหมายเลขบัตรประชาชน:\n\nชื่อ (ภาษาไทย):\nชื่อ (ภาษาอังกฤษ):\n\n`,
                    bold: true,
                  },
                  `${userInfo.applicationId}\n${userInfo.nationalId}\n\n${userInfo.firstnameThai}\n${userInfo.firstnameEnglish}`,
                  {
                    text: `\n\n\nนามสกุล (ภาษาไทย):\nนามสกุล (ภาษาอังกฤษ):\n`,
                    bold: true,
                  },
                  `\n\n\n${userInfo.lastnameThai}\n${userInfo.lastnameEnglish}`,
                  { qr: `${userInfo.profilePic}`, fit: 100 },
                ],
                ["", "", "", "", ""],
              ],
            },
          },
          {
            text: `\n`,
          },
          {
            unbreakable: true,
            stack: [
              {
                table: {
                  widths: ["80%", "20%"],
                  headerRows: 1,

                  body: [
                    [
                      {
                        text: `1 ความสามารถทางวิชาการ`,
                        bold: true,
                      },
                      {
                        text: `QR file`,
                        bold: true,
                      },
                    ],
                    [
                      {
                        text: `- หลักฐานระเบียนแสดงผลการเรียนเฉลี่ยสะสม 5 ภาคการศึกษา`,
                        bold: true,
                      },
                      parts[0].secs[0].files[0].check ||
                      parts[0].secs[0].files[0].url.length <= 0
                        ? ``
                        : {
                            qr: parts[0].secs[0].files[0].url,
                            fit: 100,
                            margin: [0, 10, 0, 10],
                            alignment: "center",
                          },
                    ],
                    [
                      {
                        text: `- หลักฐานผลการสอบแข่งขันทางวิชาการที่ได้รับรางวัล (ผลงานที่ 1)`,
                        bold: true,
                      },
                      parts[0].secs[1].files[0].check ||
                      parts[0].secs[1].files[0].url.length <= 0
                        ? ``
                        : {
                            qr: parts[0].secs[1].files[0].url,
                            fit: 100,
                            alignment: "center",
                            margin: [0, 10, 0, 10],
                          },
                    ],
                    [
                      {
                        text: `- หลักฐานผลการสอบแข่งขันทางวิชาการที่ได้รับรางวัล (ผลงานที่ 2)`,
                        bold: true,
                      },
                      parts[0].secs[2].files[0].check ||
                      parts[0].secs[2].files[0].url.length <= 0
                        ? ``
                        : {
                            qr: parts[0].secs[2].files[0].url,
                            fit: 100,
                            alignment: "center",
                            margin: [0, 10, 0, 10],
                          },
                    ],
                    [
                      {
                        text: `- หลักฐานผลการสอบแข่งขันทางวิชาการที่ได้รับรางวัล (ผลงานที่ 3)`,
                        bold: true,
                      },
                      parts[0].secs[3].files[0].check ||
                      parts[0].secs[3].files[0].url.length <= 0
                        ? ``
                        : {
                            qr: parts[0].secs[3].files[0].url,
                            fit: 100,
                            alignment: "center",
                            margin: [0, 10, 0, 10],
                          },
                    ],
                  ],
                },
              },
            ],
          },
          {
            text: `\n`,
          },

          {
            unbreakable: true,
            stack: [
              {
                table: {
                  widths: ["80%", "20%"],
                  headerRows: 1,

                  body: [
                    [
                      {
                        text: `2 และ 3 แรงผลักดันหรือแรงจูงใจในการเรียนคณะวิศวฯ และ ความสามารถในการสื่อสาร`,
                        bold: true,
                      },
                      {
                        text: `QR file`,
                        bold: true,
                      },
                    ],
                    [
                      {
                        text: `- คลิปวิดีโอแสดงถึงเจตนา และเป้าหมายของตนเองในการเข้าศึกษาที่คณะวิศวกรรมศาสตร์ จุฬาฯ`,
                        bold: true,
                      },
                      parts[1].secs[0].files[0].check ||
                      parts[1].secs[0].files[0].url.length <= 0
                        ? ``
                        : {
                            qr: parts[1].secs[0].files[0].url,
                            fit: 100,
                            alignment: "center",
                            margin: [0, 10, 0, 10],
                          },
                    ],
                  ],
                },
              },
            ],
          },

          {
            text: `\n`,
          },
          {
            unbreakable: true,
            stack: [
              {
                table: {
                  widths: ["80%", "20%"],
                  headerRows: 1,

                  body: [
                    [
                      {
                        text: `4 ความริเริ่มสร้างสรรค์และความสามารถในการแก้ปัญหา`,
                        bold: true,
                      },
                      {
                        text: `QR file`,
                        bold: true,
                      },
                    ],
                    [
                      parts[2].secs[0].files[0].des !== ""
                        ? {
                            text: `ชื่อโครงงานที่ 1 ${parts[2].secs[0].files[0].des}`,
                            bold: true,
                          }
                        : {
                            text: `ชื่อโครงงานที่ 1 -`,
                            bold: true,
                          },
                      parts[2].secs[0].files[0].des !== ""
                        ? {
                            text: `มีโครงงาน`,
                            bold: true,
                          }
                        : {
                            text: `ไม่มีโครงงาน`,
                            bold: true,
                          },
                    ],
                    [
                      {
                        text: `- โครงงานที่แสดงถึงความสามารถในการแก้ปัญหา โครงงานที่ 1 - หลักฐานประกอบ`,
                        bold: true,
                      },
                      parts[2].secs[0].files[1].check ||
                      parts[2].secs[0].files[1].url.length <= 0
                        ? ``
                        : {
                            qr: parts[2].secs[0].files[1].url,
                            fit: 100,
                            alignment: "center",
                            margin: [0, 10, 0, 10],
                          },
                    ],
                    [
                      {
                        text: `- โครงงานที่แสดงถึงความสามารถในการแก้ปัญหา โครงงานที่ 1 - สรุปผลการดำเนินโครงงาน`,
                        bold: true,
                      },
                      parts[2].secs[0].files[0].check ||
                      parts[2].secs[0].files[0].url.length <= 0
                        ? ``
                        : {
                            qr: parts[2].secs[0].files[0].url,
                            fit: 100,
                            alignment: "center",
                            margin: [0, 10, 0, 10],
                          },
                    ],
                    [
                      parts[2].secs[1].files[0].des !== ""
                        ? {
                            text: `ชื่อโครงงานที่ 2 ${parts[2].secs[1].files[0].des}`,
                            bold: true,
                          }
                        : {
                            text: `ชื่อโครงงานที่ 2 -`,
                            bold: true,
                          },
                      parts[2].secs[1].files[0].des !== ""
                        ? {
                            text: `มีโครงงาน`,
                            bold: true,
                          }
                        : {
                            text: `ไม่มีโครงงาน`,
                            bold: true,
                          },
                    ],
                    [
                      {
                        text: `- โครงงานที่แสดงถึงความสามารถในการแก้ปัญหา โครงงานที่ 2 - หลักฐานประกอบ`,
                        bold: true,
                      },
                      parts[2].secs[1].files[1].check ||
                      parts[2].secs[1].files[1].url.length <= 0
                        ? ``
                        : {
                            qr: parts[2].secs[1].files[1].url,
                            fit: 100,
                            alignment: "center",
                            margin: [0, 10, 0, 10],
                          },
                    ],
                    [
                      {
                        text: `- โครงงานที่แสดงถึงความสามารถในการแก้ปัญหา โครงงานที่ 2 - สรุปผลการดำเนินโครงงาน`,
                        bold: true,
                      },
                      parts[2].secs[1].files[0].check ||
                      parts[2].secs[1].files[0].url.length <= 0
                        ? ``
                        : {
                            qr: parts[2].secs[1].files[0].url,
                            fit: 100,
                            alignment: "center",
                            margin: [0, 10, 0, 10],
                          },
                    ],
                    [
                      parts[2].secs[2].files[0].des !== ""
                        ? {
                            text: `ชื่อโครงงานที่ 3 ${parts[2].secs[2].files[0].des}`,
                            bold: true,
                          }
                        : {
                            text: `ชื่อโครงงานที่ 3 -`,
                            bold: true,
                          },
                      parts[2].secs[2].files[0].des !== ""
                        ? {
                            text: `มีโครงงาน`,
                            bold: true,
                          }
                        : {
                            text: `ไม่มีโครงงาน`,
                            bold: true,
                          },
                    ],
                    [
                      {
                        text: `- โครงงานที่แสดงถึงความสามารถในการแก้ปัญหา โครงงานที่ 3 - หลักฐานประกอบ`,
                        bold: true,
                      },
                      parts[2].secs[2].files[1].check ||
                      parts[2].secs[2].files[1].url.length <= 0
                        ? ``
                        : {
                            qr: parts[2].secs[2].files[1].url,
                            fit: 100,
                            alignment: "center",
                            margin: [0, 10, 0, 10],
                          },
                    ],
                    [
                      {
                        text: `- โครงงานที่แสดงถึงความสามารถในการแก้ปัญหา โครงงานที่ 3 - สรุปผลการดำเนินโครงงาน`,
                        bold: true,
                      },
                      parts[2].secs[2].files[0].check ||
                      parts[2].secs[2].files[0].url.length <= 0
                        ? ``
                        : {
                            qr: parts[2].secs[2].files[0].url,
                            fit: 100,
                            alignment: "center",
                            margin: [0, 10, 0, 10],
                          },
                    ],
                  ],
                },
              },
            ],
          },
          {
            text: `\n`,
          },

          {
            unbreakable: true,
            stack: [
              {
                table: {
                  widths: ["80%", "20%"],
                  headerRows: 1,

                  body: [
                    [
                      {
                        text: `5 การศึกษาเรียนรู้ด้วยตนเองและต่อเนื่อง`,
                        bold: true,
                      },
                      {
                        text: `QR file`,
                        bold: true,
                      },
                    ],
                    [
                      {
                        text: `- หลักฐานในการเข้าค่ายอบรม/สัมมนา/เรียนออนไลน์/อื่น ๆ`,
                        bold: true,
                      },
                      parts[3].secs[0].files[0].check ||
                      parts[3].secs[0].files[0].url.length <= 0
                        ? ``
                        : {
                            qr: parts[3].secs[0].files[0].url,
                            fit: 100,
                            alignment: "center",
                            margin: [0, 10, 0, 10],
                          },
                    ],
                    [
                      {
                        text: `- สรุปผลภาพรวมในการเข้าร่วมกิจกรรมต่าง ๆ ที่ส่งเสริมด้านการเรียนรู้และพัฒนาอย่างต่อเนื่อง`,
                        bold: true,
                      },
                      parts[3].secs[1].files[0].check ||
                      parts[3].secs[1].files[0].url.length <= 0
                        ? ``
                        : {
                            qr: parts[3].secs[1].files[0].url,
                            fit: 100,
                            alignment: "center",
                            margin: [0, 10, 0, 10],
                          },
                    ],
                  ],
                },
              },
            ],
          },
          {
            text: `\n`,
          },

          // {
          //   unbreakable: true,
          //   stack: [
          //     {
          //       table: {
          //         widths: ["80%", "20%"],
          //         headerRows: 1,

          //         body: [
          //           [
          //             {
          //               text: `6 ความริเริ่มสร้างสรรค์และการทำงานเป็นกลุ่ม`,
          //               bold: true,
          //             },
          //             {
          //               text: `QR file`,
          //               bold: true,
          //             },
          //           ],
          //           [
          //             {
          //               text: `- หลักฐานในการเป็นผู้นำกลุ่ม หรือผู้มีบทบาทสำคัญในการทำกิจกรรมกลุ่ม`,
          //               bold: true,
          //             },
          //             parts[4].secs[0].files[0].check ||
          //             parts[4].secs[0].files[0].url.length <= 0
          //               ? ``
          //               : {
          //                   qr: parts[4].secs[0].files[0].url,
          //                   fit: 100,
          //                   alignment: "center",
          //                   margin: [0, 10, 0, 10],
          //                 },
          //           ],
          //           [
          //             {
          //               text: `- อธิบายสรุปถึงบทบาทหน้าที่ของตนเองภายในกลุ่ม และผลสัมฤทธิ์ของการทางานในกลุ่ม`,
          //               bold: true,
          //             },
          //             parts[4].secs[1].files[0].check ||
          //             parts[4].secs[1].files[0].url.length <= 0
          //               ? ``
          //               : {
          //                   qr: parts[4].secs[1].files[0].url,
          //                   fit: 100,
          //                   alignment: "center",
          //                   margin: [0, 10, 0, 10],
          //                 },
          //           ],
          //         ],
          //       },
          //     },
          //   ],
          // },
          // {
          //   text: `\n`,
          // },
        ],
        defaultStyle: {
          font: "THSarabunNew",
        },
      };

      const pdfDocGenerator = pdfMake.createPdf(docDefinition);

      pdfDocGenerator.getDataUrl((dataUrl) => {
        const targetElement = document.querySelector("#iframeContainer");
        const iframe = document.createElement("iframe");
        iframe.src = dataUrl;
        iframe.height = "842px";
        iframe.width = "100%";
        targetElement!.appendChild(iframe);
      });
    }
  }, [parts, userInfo]);

  return <div id="iframeContainer"></div>;
};

export default PdfGen;
