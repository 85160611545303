import React, { useCallback, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import axios from "axios";

type Props = {
  des: string;
  posturl: string;
  setParts: Function;
};

const eventHandler = (posturl: string, des: string, setParts: Function) => {
  axios.post(`/portfolio/desc/${posturl}`, {
    filedesc: des,
  })
    .then(function (response) {
      setParts(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  // console.log(des)
  // setParts([
  //   {
  //     part: 1,
  //     completed: false,
  //     secs: [
  //       {
  //         sec: 1,
  //         files: [
  //           {
  //             id: 1,
  //             url: "",
  //             des: des,
  //             check: false,
  //           },
  //         ]
  //       },
  //       {
  //         sec: 2,
  //         files: [
  //           {
  //             id: 1,
  //             url: "",
  //             des: "",
  //             check: false,
  //           },
  //         ]
  //       },
  //       {
  //         sec: 3,
  //         files: [
  //           {
  //             id: 1,
  //             url: "",
  //             des: "",
  //             check: false,
  //           },
  //         ]
  //       },
  //     ]
  //   },
  // ])
};

const GPAFormRowGroup = ({ posturl, des = "0,0,0", setParts }: Props) => {
  const [GPAX, setGPAX] = useState("0");
  const [mathGPAX, setMathGPAX] = useState("0");
  const [sciGPAX, setSciGPAX] = useState("0");

  useEffect(() => {
    const tokens = des.split(",");
    setGPAX(tokens[0] || "0");
    setMathGPAX(tokens[1] || "0");
    setSciGPAX(tokens[2] || "0");
  }, [des])

  const gpaxInputHanlder = useCallback((e: any) => {
    const gpaxPattern = /^\d(\.\d{0,2})?$/;
    const name = e.target.name;
    let value = e.target.value || "0";
    value = gpaxPattern.test(value) ? value : `${Number.parseFloat(value)}`;
    const oldValue = ((name: string) => {
      switch (name) {
        case "GPAX":
          return GPAX;
        case "mathGPAX":
          return mathGPAX;
        case "sciGPAX":
          return sciGPAX;
        default:
          return "0";
      }
    })(name);
    const validatedValue = gpaxPattern.test(value) ? value : oldValue;
    // set targeted GPAX
    ((name: string, value: any) => {
      switch (name) {
        case "GPAX":
          setGPAX(value)
          break;
        case "mathGPAX":
          setMathGPAX(value)
          break;
        case "sciGPAX":
          setSciGPAX(value)
          break;
      }
    })(name, validatedValue);
  }, [GPAX, mathGPAX, sciGPAX]);

  const gpaxInputOnBlur = useCallback((e: any) => {
    const { name, value } = e.target;
    const validatedValue = Number.parseFloat(value).toFixed(2);
    const data = ((name: string) => {
      switch (name) {
        case "GPAX":
          setGPAX(validatedValue);
          return `${validatedValue},${mathGPAX},${sciGPAX}`;
          case "mathGPAX":
          setMathGPAX(validatedValue);
          return `${GPAX},${validatedValue},${sciGPAX}`;
          case "sciGPAX":
          setSciGPAX(validatedValue);
          return `${GPAX},${mathGPAX},${validatedValue}`;
        default:
          return "0,0,0";
      }
    })(name);
    eventHandler(posturl, data, setParts);
  }, [posturl, setParts, GPAX, mathGPAX, sciGPAX])

  return (
    <>
      <Row className="pt-4">
        <Form.Label column sm={3}>
          {`ผลการเรียนเฉลี่ยสะสม (GPAX) 5 ภาคการศึกษา`}
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="number"
            name="GPAX"
            onBlur={gpaxInputOnBlur}
            onChange={gpaxInputHanlder}
            placeholder="0.00"
            value={GPAX}
            step={0.01}
            min={0}
            max={4}
          />
          <Form.Text className="text-muted">
            รองรับทศนิยมสูงสุด 2 ตำแหน่ง
          </Form.Text>
        </Col>
      </Row>
      <Row className="py-3">
        <Form.Label column sm={3}>
          {`ผลการเรียนเฉลี่ยสะสมกลุ่มวิชาคณิตศาสตร์`}
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="number"
            name="mathGPAX"
            onBlur={gpaxInputOnBlur}
            onChange={gpaxInputHanlder}
            placeholder="0.00"
            value={mathGPAX}
            step={0.01}
            min={0}
            max={4}
          />
          <Form.Text className="text-muted">
            รองรับทศนิยมสูงสุด 2 ตำแหน่ง
          </Form.Text>
        </Col>
      </Row>
      <Row className="py-3">
        <Form.Label column sm={3}>
          {`ผลการเรียนเฉลี่ยสะสมกลุ่มวิชาวิทยาศาสตร์`}
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            type="number"
            name="sciGPAX"
            onBlur={gpaxInputOnBlur}
            onChange={gpaxInputHanlder}
            placeholder="0.00"
            value={sciGPAX}
            step={0.01}
            min={0}
            max={4}
          />
          <Form.Text className="text-muted">
            รองรับทศนิยมสูงสุด 2 ตำแหน่ง
          </Form.Text>
        </Col>
      </Row>
    </>
  );
};

export default GPAFormRowGroup;
