import { makeObservable, observable, computed, action } from "mobx";
import axios from "axios";
import { isEmpty } from "lodash";

export type SignupType = {
  prefix: string;
  firstname: string;
  lastname: string;
  email: string;
  username: string;
  password: string;
  password_confirmation: string;
};

type RawUser = {
  id: number;
  username: string;
  profilePic: string | undefined;
  firstnameThai: string | undefined;
  lastnameThai: string | undefined;
  firstnameEnglish: string | undefined;
  lastnameEnglish: string | undefined;
  nationalId: string | undefined;
  applicationId: string | undefined;
  completed: boolean;
  isAdmin: boolean;
  agreedToTnC: boolean;
  finalized: boolean;
};

export type User = {
  id: number;
  firstname: string;
  lastname: string;
};

class AuthStore {
  @observable private accessToken: string | null;
  @observable private rawUser: RawUser | null;

  constructor() {
    makeObservable(this);
    // console.log(`new auth store`);
    axios.defaults.baseURL = process.env.REACT_APP_ENDPOINT;
    this.accessToken = localStorage.getItem("accessToken");
    this.rawUser = null;

    if (this.accessToken) {
      this.setAccessToken(this.accessToken);
      this.getUserInformation();
    }
  }

  @computed
  get token() {
    return this.accessToken;
  }

  @computed
  get isSignin() {
    return !isEmpty(this.accessToken);
  }

  @computed
  get isComplete() {
    return this.rawUser?.completed;
  }

  @computed
  get isfinalized() {
    return this.rawUser?.finalized;
  }

  @computed
  get user() {
    return {
      id: this.rawUser?.id,
      firstname: this.rawUser?.firstnameThai,
      lastname: this.rawUser?.lastnameThai,
    };
  }

  @computed
  get profilePic() {
    return this.rawUser?.profilePic;
  }

  @computed
  get userInfo() {
    return {
      username: this.rawUser?.username,
      profilePic: this.rawUser?.profilePic,
      firstnameThai: this.rawUser?.firstnameThai,
      lastnameThai: this.rawUser?.lastnameThai,
      firstnameEnglish: this.rawUser?.firstnameEnglish,
      lastnameEnglish: this.rawUser?.lastnameEnglish,
      nationalId: this.rawUser?.nationalId,
      applicationId: this.rawUser?.applicationId,
      agreedToTnC: this.rawUser?.agreedToTnC,
    };
  }

  @action
  async signin(method: string) {
    const redirect_uri = `${process.env.REACT_APP_MCV_REDIRECT_URI}`;
    const params = method === "google" ? "&login_page=google" : "";
    const url = `${process.env.REACT_APP_MCV_OAUTH}?response_type=code&client_id=${process.env.REACT_APP_MCV_CLIENT_ID}&redirect_uri=${redirect_uri}${params}`;
    window.location.replace(url);
  }

  @action
  async getAccessToken(code: string) {
    const res = await axios.get("/auth/oauth/login", {
      params: {
        code,
      },
    });
    const accessToken = res.data.accessToken;
    this.setAccessToken(accessToken);
    await this.getUserInformation();
  }

  @action
  async signout() {
    this.clearAccessToken();
    return true;
  }

  @action
  async getUserInformation() {
    const { data: rawUser } = await axios.get<RawUser>("/user/me")
      .catch((res: any) => {
        if (!res.status) {
          this.signout();
        }
        return res;
      });
    this.rawUser = rawUser;
  }

  @action
  async setUserInformation(rawUser: RawUser) {
    this.rawUser = rawUser;
  }

  @action
  async setFinalized() {
    this.rawUser!.finalized = true;
  }

  @action
  async setProfilePic(profilePic: string) {
    this.rawUser!.profilePic = profilePic;
  }

  @action
  private setAccessToken(accessToken: string) {
    this.accessToken = accessToken;
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  }

  @action
  private clearAccessToken() {
    this.accessToken = null;
    this.rawUser = null;
    localStorage.removeItem("accessToken");
  }
}

export default AuthStore;
