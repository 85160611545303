import React, { useContext, useEffect, useState } from "react";
import { AuthStoreContext } from "../../stores";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import "./style.scss";

const Callback = observer(() => {
  const authStore = useContext(AuthStoreContext);
  const [history] = useState(useHistory());
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");

    if (code) {
      authStore.getAccessToken(code).then(() => {
        if (!authStore.isComplete) {
          history.push("/register" || "/");
        } else {
          if (authStore.isfinalized) {
            history.push("/portfolio/finalize" || "/");
          } else {
            history.push("/portfolio" || "/");
          }
        }
      });
    }
  }, [history, authStore]);

  return (
    <div className="waiting">
      <ClipLoader size={150} color={"#334669"} loading={true} />
    </div>
  );
});

export default Callback;
