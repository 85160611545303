import React from "react";
import { Tab } from "react-bootstrap";

import Document1 from "./Document1";
import Document2 from "./Document2";
import Document3 from "./Document3";
import Document4 from "./Document4";
// import Document5 from "./Document5";
import { Part } from "../../../types";

type Props = {
  part: Part;
  setParts: Function;
};

const Portfolio = ({ part, setParts }: Props) => {
  return (
    <Tab.Pane eventKey="#1">
      {part.part === 1 && <Document1 docs={part} setParts={setParts} />}
      {part.part === 2 && <Document2 docs={part} setParts={setParts} />}
      {part.part === 4 && <Document3 docs={part} setParts={setParts} />}
      {part.part === 5 && <Document4 docs={part} setParts={setParts} />}
      {/* {part.part === 6 && <Document5 docs={part} setParts={setParts} />} */}
    </Tab.Pane>
  );
};

export default Portfolio;
