import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import SingleFileAutoSubmit from "../../../../components/SingleFileAutoSubmit";
import { Part } from "../../../../types";

type Props = {
  docs: Part;
  setParts: Function;
};

const Document4 = ({ docs, setParts }: Props) => {
  return (
    <div>
      <Card bg="lite" text="dark" className="mb-2">
        <Card.Header className="text-center">
          5) การศึกษาเรียนรู้ด้วยตนเองและต่อเนื่อง
        </Card.Header>

        <ListGroup className="list-group-flush">
          <ListGroup.Item>
            <Card.Body className="text-danger">
              <Card.Title> คำอธิบายวิธีการอัปโหลด </Card.Title>
              <Card.Text>
                - นามสกุลของไฟล์หลักฐานที่สามารถแนบได้ คือ .jpg หรือ .pdf
                เท่านั้น
              </Card.Text>
              <Card.Text>
                - หากหลักฐานแต่ละประเภทมีหลายไฟล์
                ให้นำไฟล์มาเรียงต่อกันเป็นไฟล์เดียว
              </Card.Text>
              <Card.Text>
                - หากมีการอัปโหลดไฟล์ใหม่
                ไฟล์เก่าที่อัปโหลดไว้จะถูกเขียนทับด้วยไฟล์ใหม่
              </Card.Text>
            </Card.Body>
          </ListGroup.Item>
        </ListGroup>

        <ListGroup.Item>
          <Card.Body>
            <Card.Title>
              หลักฐานในการเข้าค่ายอบรม/สัมมนา/การเรียนออนไลน์/หรือหลักฐานอื่น ๆ {" "}
              ซึ่งแสดงว่าเป็นคนรักการเรียนรู้และพัฒนาอย่างต่อเนื่อง (รวมเป็นไฟล์เดียว)
            </Card.Title>
            <div className="pt-4">
              <SingleFileAutoSubmit
                posturl={`5/1/1`}
                acceptType=".jpg,.pdf"
                qrurl={docs.secs[0].files[0].url}
                setParts={setParts}
                check={docs.secs[0].files[0].check}
              />
            </div>
          </Card.Body>
        </ListGroup.Item>
        <ListGroup.Item>
          <Card.Body>
            <Card.Title>
              สรุปผลการศึกษาเรียนรู้ด้วยตนเองและต่อเนื่องที่สอดคล้องกับหลักฐาน {" "}
              และขอให้วิเคราะห์ถึง จุดแข็ง/จุดอ่อน ศักยภาพในด้านใดของตนเองที่อยากจะพัฒนา
            </Card.Title>
            (ความยาวไม่เกิน 1 หน้ากระดาษ A4)
            <div className="pt-4">
              <SingleFileAutoSubmit
                posturl={`5/2/1`}
                acceptType=".jpg,.pdf"
                qrurl={docs.secs[1].files[0].url}
                setParts={setParts}
                check={docs.secs[1].files[0].check}
                custom={2}
              />
            </div>
          </Card.Body>
        </ListGroup.Item>
      </Card>
    </div>
  );
};

export default Document4;
