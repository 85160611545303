import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { observer } from "mobx-react-lite";
import { AuthStoreContext } from "../../stores";
import { Part } from "../../types";
import PdfGenFinal from "./PdfGenFinal";

const PortfolioFinal = observer(() => {
  const authStore = useContext(AuthStoreContext);
  const [parts, setParts] = useState<Part[]>([]);
  const userInfo = authStore.userInfo;

  useEffect(() => {
    axios.get(`/portfolio/me`).then((response) => {
      setParts(response.data);
    });
  }, []);

  return <PdfGenFinal parts={parts} userInfo={userInfo} />;
});

export default PortfolioFinal;
