import { useEffect, useState } from "react";
import { Button, Toast } from "react-bootstrap";
import './style.scss';

let addAnalytics = false;

const Banner = () => {
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);
  const [toggle, setToggle] = useState(false);

  const handleClose = () => {
    setShowA(false);
    setShowB(false);
  };
  const toggleShowB = () => setShowB(true);

  const updateToggle = () => {
    setToggle(!toggle);
  }

  const checkAnalytics = () => {
    (toggle) ? confirmAnalytics() : confirm();
  }

  const setAnalytics = () => {
    localStorage.setItem('addAnalytics', 'true');
    addAnalytics = true;

    // Create a gtag script element.
    const gTagScript = document.createElement('script');
    gTagScript.async = true;
    gTagScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTIC_KEY}`;

    // Append the script element to the head section of the document.
    document.head.appendChild(gTagScript);

    // // Create a gtag executing script element.
    const gTagExecutingScript = document.createElement('script');
    gTagExecutingScript.append(`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTIC_KEY}');`);

    // Append the executing script element to the head section of the document.
    document.head.appendChild(gTagExecutingScript);
  }

  const confirm = () => {
    handleClose();
    localStorage.setItem('state', 'true');
  }

  const confirmAnalytics = () => {
    confirm();
    localStorage.setItem('analytics', 'true');
    setAnalytics();
  }

  useEffect(() => {
    if (!localStorage.getItem('state')) {
      setShowA(true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem('analytics') && !addAnalytics) {
      setAnalytics();
    }
  }, []);

  return (
    <>
      <Toast 
        show={showA}
        style={{
          maxWidth: "10000px",
          width: "70%",
          position: "fixed",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 9999
        }}
      >
        <Toast.Header
          closeButton={false}
        >
          <strong className="text-dark">เว็บไซต์นี้ใช้คุกกี้</strong>
        </Toast.Header>
        <Toast.Body>
          เราใช้คุกกี้เพื่อเพิ่มประสิทธิภาพและประสบการณ์ที่ดีในการใช้งานเว็บไซต์ให้สะดวกยิ่งขึ้น คุณสามารถเลือกตั้งค่าความยินยอมการใช้งานคุกกี้ได้ที่ “การตั้งค่าคุกกี้”<br />
          <Button className="float-right mt-2" variant="primary" onClick={confirmAnalytics}>
            ยอมรับทั้งหมด
          </Button>
          <Button className="float-right m-2" variant="secondary" onClick={toggleShowB}>
            การตั้งค่าคุกกี้
          </Button>
          <br />
          <a href="#" className="link-dark">
            นโยบายเกี่ยวกับการใช้คุกกี้
          </a>
        </Toast.Body>
      </Toast>

      <Toast
        show={showB}
        style={{
          maxWidth: "10000px",
          width: "40%",
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translateX(-50%) translateY(-50%)",
          zIndex: 9999
        }}
      >
        <Toast.Header
          closeButton={false}
        >
          <strong className="text-dark">การตั้งค่าคุกกี้</strong>
        </Toast.Header>
        <Toast.Body>
          <strong>คุกกี้ที่จำเป็นอย่างยิ่ง (Strictly Necessary Cookies)</strong>
          <div className="custom-control custom-switch float-right">
            <input type="checkbox" className="custom-control-input" disabled checked={true} id="customSwitch1"/>
            <label className="custom-control-label" htmlFor="customSwitch1"></label>
          </div><br />
          คุกกี้ประเภทนี้มีความจำเป็นต่อการให้บริการเว็บไซต์ของเรา เพื่อให้ท่านสามารถเข้าใช้งานในส่วนต่าง ๆ ของเว็บไซต์ได้ รวมถึงช่วยจดจำข้อมูลที่ท่านเคยให้ไว้ผ่านเว็บไซต์ คุกกี้ประเภทนี้ไม่สามารถปิดการใช้งานได้ <br /><br />
          <strong>คุกกี้เพื่อการวิเคราะห์ (Analytical Cookies)</strong>
          <div className="custom-control custom-switch float-right" onChange={updateToggle}>
            <input type="checkbox" className="custom-control-input" id="customSwitch2"/>
            <label className="custom-control-label" htmlFor="customSwitch2"></label>
          </div><br />
          คุกกี้ประเภทนี้ช่วยให้เราทราบถึงการปฏิสัมพันธ์ของผู้ใช้งานในการใช้บริการเว็บไซต์ของเรา รวมถึงหน้าเพจหรือพื้นที่ใดของเว็บไซต์ที่ได้รับความนิยม ตลอดจนการวิเคราะห์ข้อมูลด้านอื่น ๆ เรายังใช้ข้อมูลนี้เพื่อการปรับปรุงการทำงานของเว็บไซต์ และเพื่อเข้าใจพฤติกรรมของผู้ใช้งานมากขึ้น <br /><br />
          <Button variant="secondary" className="float-right mb-2" onClick={checkAnalytics}>
            ยืนยันตัวเลือก
          </Button>
          <Button variant="primary" className="float-right mx-2" onClick={confirmAnalytics}>
            ยอมรับทั้งหมด
          </Button>
        </Toast.Body>
      </Toast>
    </>
  );
}
  
export default Banner;