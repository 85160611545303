import React, { useCallback, useContext } from "react";
import { AuthStoreContext } from "../../stores";
import { observer } from "mobx-react-lite";
import { Navbar, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Navigation = observer(() => {
  const authStore = useContext(AuthStoreContext);
  const userInfo = authStore.user;
  const signout = useCallback(() => {
    authStore.signout();
  }, [authStore]);

  return (
    <>
      <div className="sticky-top">
        <Navbar bg="light" variant="light">
          <Navbar.Brand className="pl-5">
            <img
              alt=""
              src="/chula-engineering-logo.png"
              width="240"
              height="27"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>

          {authStore.isSignin ? (
            <Navbar.Collapse className="justify-content-end pr-5">
              <Navbar.Text>Signed in as:</Navbar.Text>
              <Navbar.Text>
                <NavDropdown
                  title={
                    userInfo.firstname === undefined
                      ? "User "
                      : `${userInfo.firstname} ${userInfo.lastname}`
                  }
                  id="basic-nav-dropdown"
                >
                  {!authStore.isfinalized ? (
                    <LinkContainer to="/register">
                      <NavDropdown.Item>แก้ไขข้อมูลส่วนตัว</NavDropdown.Item>
                    </LinkContainer>
                  ) : (
                    <NavDropdown.Item disabled>
                      แก้ไขข้อมูลส่วนตัว
                    </NavDropdown.Item>
                  )}

                  <NavDropdown.Divider />
                  <NavDropdown.Item
                    href="https://www.mycourseville.com/api/logout"
                    onClick={signout}
                    className="text-dark"
                  >
                    ออกจากระบบ
                  </NavDropdown.Item>
                </NavDropdown>
              </Navbar.Text>
            </Navbar.Collapse>
          ) : null}
        </Navbar>
      </div>
    </>
  );
});

export default Navigation;
