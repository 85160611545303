import React from "react";
import { Card, ListGroup, Row, Col } from "react-bootstrap";
import SingleFileAutoSubmit from "../../../../components/SingleFileAutoSubmit";
import { Part } from "../../../../types";

import Description from "./Description";

type Props = {
  docs: Part;
  setParts: Function;
};

const Document3 = ({ docs, setParts }: Props) => {
  return (
    <div>
      <Card bg="lite" text="dark" className="mb-2">
        <Card.Header className="text-center">
          4) ความคิดริเริ่มสร้างสรรค์และความสามารถในการแก้ปัญหา
        </Card.Header>

        <ListGroup className="list-group-flush">
          <ListGroup.Item>
            <Card.Body className="text-danger">
              <Card.Title> คำอธิบายวิธีการอัปโหลด </Card.Title>
              <Card.Text>
                - นามสกุลของไฟล์หลักฐานที่สามารถแนบได้ คือ .jpg หรือ .pdf
                เท่านั้น
              </Card.Text>
              <Card.Text>
                - หากหลักฐานแต่ละประเภทมีหลายไฟล์
                ให้นำไฟล์มาเรียงต่อกันเป็นไฟล์เดียว
              </Card.Text>
              <Card.Text>
                - หากมีการอัปโหลดไฟล์ใหม่
                ไฟล์เก่าที่อัปโหลดไว้จะถูกเขียนทับด้วยไฟล์ใหม่
              </Card.Text>
              <Card.Text>- หากไม่มีโครงงานไม่ต้องใส่ชื่อโครงงาน</Card.Text>
            </Card.Body>
          </ListGroup.Item>
        </ListGroup>

        <ListGroup.Item>
          <Card.Body>
            <Row className="py-3">
              <Col sm={3}>
                <Card.Title>
                  {!docs.secs[0].files[0].check
                    ? `ชื่อโครงงานที่ 1`
                    : `โครงงานที่ 1`}
                </Card.Title>
              </Col>
              <Col sm={9}>
                {!docs.secs[0].files[0].check && (
                  <Description
                    posturl={`4/1/1`}
                    des={docs.secs[0].files[0].des}
                    setParts={setParts}
                  />
                )}
              </Col>
            </Row>
            <div className="pb-4">
              <p className="font-weight-bold">
                - หลักฐานประกาศนียบัตร/รางวัล ที่ได้รับจากการประกวด (ถ้ามี)
              </p>
              <SingleFileAutoSubmit
                posturl={`4/1/2`}
                acceptType=".jpg,.pdf"
                qrurl={docs.secs[0].files[1].url}
                setParts={setParts}
                check={docs.secs[0].files[1].check}
              />
            </div>
            <p className="font-weight-bold">
              - สรุปผลงาน/โครงงานทางวิชาการ วิศวกรรม หรืองานวิจัย ออกแบบพัฒนาที่ทำได้
              โดยแสดงให้เห็นความคิดสร้างสรรค์/ผลลัพธ์ที่ช่วยแก้ปัญหา/ที่เป็นประโยชน์ ฯลฯ
              หากเป็นงานกลุ่มขอให้ระบุหน้าที่ งานที่รับผิดชอบ
            </p>
            (ความยาวไม่เกิน 1 หน้า กระดาษ A4)
            <div className="pt-4">
              <SingleFileAutoSubmit
                posturl={`4/1/1`}
                acceptType=".jpg,.pdf"
                qrurl={docs.secs[0].files[0].url}
                setParts={setParts}
                check={docs.secs[0].files[0].check}
                custom={1}
              />
            </div>
            {/* {!docs.secs[0].files[0].check && (
              <div className="pt-4">
                <p className="font-weight-bold">
                  - หลักฐานประกาศนียบัตร/รางวัล ที่ได้รับจากการประกวด (ถ้ามี)
                </p>
                <SingleFileAutoSubmit
                  posturl={`4/1/2`}
                  acceptType=".jpg,.pdf"
                  qrurl={docs.secs[0].files[1].url}
                  setParts={setParts}
                  check={docs.secs[0].files[1].check}
                />
              </div>
            )} */}
          </Card.Body>
        </ListGroup.Item>
        <ListGroup.Item>
          <Card.Body>
            <Row className="py-3">
              <Col sm={3}>
                <Card.Title>
                  {!docs.secs[1].files[0].check
                    ? `ชื่อโครงงานที่ 2`
                    : `โครงงานที่ 2`}
                </Card.Title>
              </Col>
              <Col sm={9}>
                {!docs.secs[1].files[0].check && (
                  <Description
                    posturl={`4/2/1`}
                    des={docs.secs[1].files[0].des}
                    setParts={setParts}
                  />
                )}
              </Col>
            </Row>
            <div className="pb-4">
              <p className="font-weight-bold">
                - หลักฐานประกาศนียบัตร/รางวัล ที่ได้รับจากการประกวด (ถ้ามี)
              </p>
              <SingleFileAutoSubmit
                posturl={`4/2/2`}
                acceptType=".jpg,.pdf"
                qrurl={docs.secs[1].files[1].url}
                setParts={setParts}
                check={docs.secs[1].files[1].check}
              />
            </div>
            <p className="font-weight-bold">
              - สรุปผลงาน/โครงงานทางวิชาการ วิศวกรรม หรืองานวิจัย ออกแบบพัฒนาที่ทำได้
              โดยแสดงให้เห็นความคิดสร้างสรรค์/ผลลัพธ์ที่ช่วยแก้ปัญหา/ที่เป็นประโยชน์ ฯลฯ
              หากเป็นงานกลุ่มขอให้ระบุหน้าที่ งานที่รับผิดชอบ
            </p>
            (ความยาวไม่เกิน 1 หน้า กระดาษ A4)
            <div className="pt-4">
              <SingleFileAutoSubmit
                posturl={`4/2/1`}
                acceptType=".jpg,.pdf"
                qrurl={docs.secs[1].files[0].url}
                setParts={setParts}
                check={docs.secs[1].files[0].check}
                custom={1}
              />
            </div>
            {/* {!docs.secs[1].files[0].check && (
              <div className="pt-4">
                <p className="font-weight-bold">
                  - หลักฐานประกาศนียบัตร/รางวัล ที่ได้รับจากการประกวด (ถ้ามี)
                </p>
                <SingleFileAutoSubmit
                  posturl={`4/2/2`}
                  acceptType=".jpg,.pdf"
                  qrurl={docs.secs[1].files[1].url}
                  setParts={setParts}
                  check={docs.secs[1].files[1].check}
                />
              </div>
            )} */}
          </Card.Body>
        </ListGroup.Item>
        <ListGroup.Item>
          <Card.Body>
            <Row className="py-3">
              <Col sm={3}>
                <Card.Title>
                  {!docs.secs[2].files[0].check
                    ? `ชื่อโครงงานที่ 3`
                    : `โครงงานที่ 3`}
                </Card.Title>
              </Col>
              <Col sm={9}>
                {!docs.secs[2].files[0].check && (
                  <Description
                    posturl={`4/3/1`}
                    des={docs.secs[2].files[0].des}
                    setParts={setParts}
                  />
                )}
              </Col>
            </Row>
            <div className="pb-4">
              <p className="font-weight-bold">
                - หลักฐานประกาศนียบัตร/รางวัล ที่ได้รับจากการประกวด (ถ้ามี)
              </p>
              <SingleFileAutoSubmit
                posturl={`4/3/2`}
                acceptType=".jpg,.pdf"
                qrurl={docs.secs[2].files[1].url}
                setParts={setParts}
                check={docs.secs[2].files[1].check}
              />
            </div>
            <p className="font-weight-bold">
              - สรุปผลงาน/โครงงานทางวิชาการ วิศวกรรม หรืองานวิจัย ออกแบบพัฒนาที่ทำได้
              โดยแสดงให้เห็นความคิดสร้างสรรค์/ผลลัพธ์ที่ช่วยแก้ปัญหา/ที่เป็นประโยชน์ ฯลฯ
              หากเป็นงานกลุ่มขอให้ระบุหน้าที่ งานที่รับผิดชอบ
            </p>
            (ความยาวไม่เกิน 1 หน้า กระดาษ A4)
            <div className="pt-4">
              <SingleFileAutoSubmit
                posturl={`4/3/1`}
                acceptType=".jpg,.pdf"
                qrurl={docs.secs[2].files[0].url}
                setParts={setParts}
                check={docs.secs[2].files[0].check}
                custom={1}
              />
            </div>
            {/* {!docs.secs[2].files[0].check && (
              <div className="pt-4">
                <p className="font-weight-bold">
                  - หลักฐานประกาศนียบัตร/รางวัล ที่ได้รับจากการประกวด (ถ้ามี)
                </p>
                <SingleFileAutoSubmit
                  posturl={`4/3/2`}
                  acceptType=".jpg,.pdf"
                  qrurl={docs.secs[2].files[1].url}
                  setParts={setParts}
                  check={docs.secs[2].files[1].check}
                />
              </div>
            )} */}
          </Card.Body>
        </ListGroup.Item>
      </Card>
    </div>
  );
};

export default Document3;
