import React, { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Form, Row, Col } from "react-bootstrap";
import Dropzone, { IDropzoneProps } from "react-dropzone-uploader";
import { AuthStoreContext } from "../../stores";
import QRCode from "qrcode.react";

import "react-toastify/dist/ReactToastify.css";
import "react-dropzone-uploader/dist/styles.css";
import axios from "axios";

type Props = {
  posturl: string;
  check: boolean;
  setParts: Function;
  custom?: number;
};

type UploadProps = {
  getUploadParams: any;
  handleChangeStatus: any;
  acceptType: string;
  url: string;
};

type SingleFileAutoSubmitProp = {
  posturl: string;
  acceptType: string;
  qrurl: string;
  setParts: Function;
  check?: boolean;
  custom?: number;
};

const onClickHandler = (
  posturl: string,
  check: boolean,
  setParts: Function
) => {
  axios
    .post(`/portfolio/nofile/${posturl}`, {
      nofile: check,
    })
    .then(function (response) {
      setParts(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const CheckBox = ({ posturl, check, setParts, custom }: Props) => {
  return (
    <Form.Group as={Row}>
      <Form.Label as="legend" column sm={3}>
        {custom === 1 ? (
          <p>
            สรุปรายละเอียดโครงงาน <span className="text-danger">*</span>
          </p>
        ) : custom === 2 ? (
          <p>
            สรุปผลการการศึกษาเรียนรู้ด้วยตนเอง <span className="text-danger">*</span>
          </p>
        ) : (
          <p>
            หลักฐาน <span className="text-danger">*</span>
          </p>
        )}
      </Form.Label>
      <Col sm={9}>
        <Form.Check
          type="radio"
          label={
            custom === 1
              ? "มีสรุปรายละเอียดโครงงาน"
              : custom === 2
              ? "มีสรุปผลการการศึกษาเรียนรู้ด้วยตนเอง"
              : "มีหลักฐาน"
          }
          name={posturl}
          id={`${posturl}-1`}
          onClick={() => onClickHandler(posturl, false, setParts)}
          defaultChecked={!check}
        />
        <Form.Check
          type="radio"
          label={
            custom === 1
              ? "ไม่มีสรุปรายละเอียดโครงงาน"
              : custom === 2
              ? "ไม่มีสรุปผลการการศึกษาเรียนรู้ด้วยตนเอง"
              : "ไม่มีหลักฐาน"
          }
          name={posturl}
          id={`${posturl}-2`}
          onClick={() => onClickHandler(posturl, true, setParts)}
          defaultChecked={check}
        />
      </Col>
    </Form.Group>
  );
};

const UploadBox = ({
  getUploadParams,
  handleChangeStatus,
  acceptType,
  url,
}: UploadProps) => {
  return (
    <React.Fragment>
      <ToastContainer position="bottom-right" />
      <Row className="pt-2">
        <Col sm={8}>
          <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            accept={acceptType}
            maxFiles={1}
            multiple={false}
            canCancel={false}
            inputContent={
              url === ""
                ? "คลิกหรือวางไฟล์เพื่ออัปโหลด"
                : "คลิกหรือวางไฟล์เพื่ออัปโหลดไฟล์ใหม่"
            }
            styles={{
              dropzoneActive: { borderColor: "green" },
              dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
            }}
          />
        </Col>
        <Col sm={4}>
          {url === "" ? (
            <div>
              <p className="text-center">No file uploaded</p>
            </div>
          ) : (
            <div className="text-center">
              <QRCode value={url} />
              <br />
              <a href={url} target="_blank" rel="noopener noreferrer">
                ตรวจสอบไฟล์
              </a>
            </div>
          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

const SingleFileAutoSubmit = ({
  posturl,
  acceptType,
  qrurl,
  setParts,
  check,
  custom,
}: SingleFileAutoSubmitProp) => {
  const authStore = useContext(AuthStoreContext);

  const getUploadParams: IDropzoneProps["getUploadParams"] = () => {
    return {
      url: `${process.env.REACT_APP_ENDPOINT}/portfolio/upload/${posturl}`,
      headers: { Authorization: `Bearer ${authStore.token}` },
    };
  };

  const handleChangeStatus: IDropzoneProps["onChangeStatus"] = (
    { meta, remove, xhr },
    status
  ) => {
    if (status === "headers_received") {
      toast.success(`${meta.name} uploaded!`);
      remove();
    } else if (status === "aborted") {
      toast.error(`${meta.name}, upload failed...`);
    } else if (status === "done") {
      let res = JSON.parse(xhr!.response);
      setParts(res);
    }
  };

  return (
    <React.Fragment>
      {check !== undefined ? (
        <div>
          <CheckBox
            posturl={posturl}
            check={check}
            setParts={setParts}
            custom={custom}
          />
          {!check && (
            <UploadBox
              getUploadParams={getUploadParams}
              handleChangeStatus={handleChangeStatus}
              acceptType={acceptType}
              url={qrurl}
            />
          )}
        </div>
      ) : (
        <UploadBox
          getUploadParams={getUploadParams}
          handleChangeStatus={handleChangeStatus}
          acceptType={acceptType}
          url={qrurl}
        />
      )}
    </React.Fragment>
  );
};

export default SingleFileAutoSubmit;
